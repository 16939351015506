import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

export const client = sanityClient({
    projectId:'oj58kvhv',
    dataset:'production',
    apiVersion:'2022-02-01',
    useCdn:true,
    token:'skRhmrQrr8gDUKrdPnrAYxPAriX5S9tuCn5aDj7p6ru7KRMocuIkrAGpanqMLDRw7mReEBQlwfPodMQxubxSnYt6xCkBVmVjWyLbod4SEJ6x5QuCod8Dks5HcuEUgd4Pti7SRjwCv6LGCTtGmxiGZukB2rdCaEBV16XrNi98dIZmljt1kvoA',

})

const builder = imageUrlBuilder(client)

export const urlFor = (source) => builder.image(source)