import React,{useState,useEffect} from 'react'
import {motion} from 'framer-motion'
import {Tooltip} from 'react-tooltip'
import {urlFor, client} from '../../client'

import './Skills.scss'
const Skills = () => {
    const [skills,setSkills] = useState([])
    const [experiences,setExperiences] = useState([])
    useEffect(()=>{
        const query = '*[_type=="skills"]'
        client.fetch(query).then((data)=> setSkills(data))
    },[])
    useEffect(()=>{
        const query = '*[_type=="mainExperiences"]'
        client.fetch(query).then((data)=> {
            console.log(data)
            setExperiences(data)
        })
    },[])
    return (
        <div className='app__skills section__padding__top' id='skills'>
            <h2 className='head-text'>Skills & Experience</h2>
            <div className='app__skills-container'>
                <motion.div 
                    // whileInView={{x:[-100,0]}}
                    // transition={{duration:0.5}}
                    className='app__skills-list'>
                    {skills.map((skill)=>(
                        <motion.div 
                            whileInView={{opacity:[0,1]}}
                            transition={{duration:1}}
                            whileHover={{scale:1.1}}
                            className='app__skills-list_skill'
                        >
                            <div className='app__skills-list_skill-image'>
                                <img src={urlFor(skill.icon)} alt={skill.name} />
                            </div>
                            <p className='p-text'>{skill.name}</p>
                        </motion.div>
                    ))}
                </motion.div>
                <motion.div 
                    
                    className='app__skills-experience'>
                    {experiences.map((experience)=>(
                        <>
                        <div
                            data-tooltip-id={experience.name}
                            key={experience.name}
                            className='app__skills-experience_container'>

                            <p>{experience.from} - {experience.to}</p>
                            <motion.div 
                                className='app__skills-experience_container-info'>
                                <h4>{experience.name}</h4>
                                <p>{experience.company}</p>
                            </motion.div>
                        </div>
                        {/* <Tooltip
                            id={experience.name}
                            effect="solid"
                            arrowColor="#fff"
                            className="skills-tooltip"
                        >
                            {experience.desc}
                        </Tooltip> */}
                        </>
                    ))}
                </motion.div>
            </div>
        </div>
    )
}

export default Skills