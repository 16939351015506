import React from 'react'
import { Navbar,Header,About,Work,Skills,Contact } from './container'
import './App.scss'

const App = () => {
  return (
    <div className='app'>
        <Navbar/>
        <Header/>
        <About/>
        <Work/>
        <Skills/>
        <Contact/>
    </div>
  )
}

export default App