import React,{useState,useEffect} from 'react'
import {motion} from 'framer-motion'
import './About.scss'
import { urlFor,client } from '../../client'

import ml from '../../assets/machine_learning.png'
// const abouts = [
//     {title:'Web Development',description:'I am a good Web Developer',imgUrl:ml},
//     {title:'Frontend Development',description:'I am a good Web Developer',imgUrl:ml},
//     {title:'Backend Development',description:'I am a good Web Developer',imgUrl:ml},
//     {title:'MERN Stack',description:'I am a good Web Developer',imgUrl:ml}
// ]
const About = () => {

  const [abouts,setAbouts] = useState([])
  
  useEffect(()=>{
    const query = '*[_type=="abouts"]'
    client.fetch(query).then((data)=> setAbouts(data))
  },[])

  return (
    <div className='app__about section__padding__top' id='about'>
        <h2 className='head-text'>
            I know that
            <span> Good Dev</span><br />
            means
            <span> Good Business</span>
        </h2>
        <div className='app__profiles'>
            {abouts.map((about,index)=>(
                <motion.div
                    whileInView={{opacity:1}}
                    whileHover={{scale:1.1}}
                    transition={{duration:0.5,type:'tween'}}
                    className='app__profile-item'
                    key = {about.title+index}
                >
                    <img src={urlFor(about.imgUrl)} alt={about.title} />
                    <h2 className='bold-text' style={{marginTop:20}}>{about.title}</h2>
                    <p className='p-text' style={{marginTop:10}}>{about.description}</p>

                </motion.div>
            ))}

        </div>
    </div>
  )
}

export default About