import React from 'react'
import { motion } from 'framer-motion'
import './Header.scss'
import image from '../../assets/photo_img.jpg'
const Header = () => {
  return (
    <div className='app__header section__padding__top' id='home'>
        <motion.div 
            whileInView={{x:[-100,0],opacity:[0,1]}}
            transition={{duration:0.5}}
            className='app__header-info'
        >
            <div className='app__header-badge'>
                <div className='badge-cmp app__flex'>
                    {/* <span>👋</span> */}
                    <div style={{marginLeft:20}}>
                        <p className='p-text'>Hello, I am</p>
                        <h1 className='head-text'>Vamsi</h1>
                    </div>
                </div>
                <div className='tag-cmp'>
                    <p className='p-text'>-- Software Engineer</p>
                    <p className='p-text'>-- Web Developer</p>
                    <p className='p-text'>-- Graduate Student</p>
                </div>
            </div>
        </motion.div>
        
        <motion.div
            whileInView={{x:[100,0],opacity:[0,1]}}
            transition={{duration:0.5}}
            className='app__header-img'
        >
            <img className='main_img' src={image} alt="My Image" />
        </motion.div>
    </div>
  )
}

export default Header