import React, {useState,useEffect} from 'react'
import {AiFillEye, AiFillGithub} from 'react-icons/ai'
import {motion} from 'framer-motion'
import {urlFor,client} from '../../client'
import './Work.scss'

const Work = () => {
    const [portfolio,setPortfolio] = useState([])
    useEffect(()=>{
        const query = '*[_type=="works"]'
        client.fetch(query).then((data)=> setPortfolio(data))
      },[])
  return (
    <div className='app__work section__padding__top' id='work'>
        <h2 className='head-text'>My <span>Portfolio</span></h2>
        <div className='app__work-portfolio'>
            {portfolio.map((project,index)=>(
                <motion.div
                    whileInView={{opacity:1}}
                    whileHover={{scale:1.1}}
                    transition={{duration:0.5,type:'tween'}}
                    className='app__profile-item'
                    key = {project.title+index}
                >
                    <div className='app__profile-image'>
                        <img src={urlFor(project.imgUrl)} alt={project.title} />
                        <motion.div
                            whileHover={{opacity:[0,1],scale:[0.9,1]}}
                            transition={{duration:0.25, ease:'easeInOut', staggerChildren: 0.5}}
                            className='app__work-hover app__flex'
                        >
                            {project.projectLink?(
                                <a href={project.projectLink} target='_blank'>
                                    <motion.div
                                        whileInView={{scale:[0,1]}}
                                        whileHover={{opacity:[1,0.9]}}
                                        transition={{duration:0.2}}
                                        className='app__flex'
                                    >
                                        <AiFillEye style={{fontSize:25}}/>
                                    </motion.div>
                            </a>
                            ):(<></>)}
                            
                            <a href={project.codeLink} target='_blank'>
                                <motion.div
                                    whileInView={{scale:[0,1]}}
                                    whileHover={{opacity:[1,0.9]}}
                                    transition={{duration:0.2}}
                                    className='app__flex'
                                >
                                    <AiFillGithub style={{fontSize:25}}/>
                                </motion.div>
                            </a>
                        </motion.div>
                    </div>
                    <h2 className='bold-text' style={{marginTop:20}}>{project.title}</h2>
                    <p className='p-text' style={{marginTop:10}}>{project.description}</p>

                </motion.div>
            ))}

        </div>
    </div>
    
  )
}

export default Work