import React,{useState} from 'react'

import './Contact.scss'
import mail from '../../assets/mail.png'
import mobile from '../../assets/mobile.png'

const Contact = () => {
    const [formData,setFormData] = useState({name:'',email:'',message:''})
    const {namee,email,message} = formData;
    const handleChangeInput = (e) => {
        const {name,value} = e.target;
        setFormData({...formData,[name]:value})
    }
    const handleSubmit = () =>{
        console.log("Submitted")
    }
  return (
    <div className='app__contact section__padding__top' id='contact'>
        <h2 className='head-text'>Contact Me</h2>
        <div className='app__contact-main'>
            <div className='app__contact-main_email'>
                <img src={mail} alt="email" />
                <a href='mailto:mvamsimvkr@gmail.com'>mvamsimvkr@gmail.com</a>
            </div>
            <div className='app__contact-main_phone'>
                <img src={mobile} alt="mobile" />
                <a href="tel: +1 514 691 1241">+1 514 691-1241</a>
            </div>
        </div>
        <div className='app__contact-form'>
            <div>
                <input className='p-text' type="text" placeholder='Your Name' name='name' value={namee} onChange={handleChangeInput}/>
            </div>
            <div>
                <input className='p-text' type="email" placeholder='Your Email' name='email' value={email} onChange={handleChangeInput}/>
            </div>
            <div>
                <textarea className='p-text' placeholder='Your Message' value={message} name="message" onChange={handleChangeInput}></textarea>
            </div>
            <button type='button' className='p-text' onClick={handleSubmit}>Send Message</button>
        </div>
    </div>
  )
}

export default Contact