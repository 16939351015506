import React,{useState,useEffect} from 'react'
import './Navbar.scss'
import { RiMenu3Line, RiCloseLine} from 'react-icons/ri'
const Navbar = () => {
    const GITHUB = 'https://github.com/vamsivk18'
    const RESUME = 'https://drive.google.com/file/d/1rF6FciizddMUpFP7z6fJQx1TTQhftaNC/view?usp=drive_link'
    const [toggleMenu,setToggleMenu] = useState(false)
    return (
    <nav className='app__navbar'>
        <div className='app__navbar-name'>
            <h1>Vamsi</h1>
        </div>
        <ul className='app__navbar-links'>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#work">Work</a></li>
            <li><a href="#skills">Skills</a></li>
            <li><a href="#contact">Contact</a></li>
        </ul>
        <div className='app__navbar-git'>
            <a href={RESUME} target='blank' className='resume'>Resume</a>
            <a href={GITHUB} target='blank' className='github'>Github</a>
        </div>
        <div className='app__navbar-menu'>
            {toggleMenu ? <>
                            <RiCloseLine className='app__navbar-toggle' color='#313bac' size={27} onClick={()=>setToggleMenu(false)}/>
                            <div className='app__navbar-menu_container scale-up-center'>
                                <div className='app__navbar-links app__navbar-menu_container-links'>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#about">About</a></li>
                                    <li><a href="#work">Work</a></li>
                                    <li><a href="#skills">Skills</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </div>
                                <div className='app__navbar-git app__navbar-menu_container-git'>
                                    <a href={RESUME} target='blank' className='resume'>Resume</a>
                                    <a href={GITHUB} target='blank' className='github'>Github</a>
                                </div>
                            </div>
                            </>
                            :
                            <RiMenu3Line className='gpt3__navbar-toggle' color='#313bac' size={27} onClick={()=>setToggleMenu(true)}/>
            }
        </div>
    </nav>
  )
}

export default Navbar